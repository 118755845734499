<template>
  <div>
    <v-container fluid>
      <v-card-title>
        유저 그룹
        <v-spacer />
        <v-btn outlined to="/group/create">
          <v-icon left>mdi-plus</v-icon>
          등록
        </v-btn>
      </v-card-title>
      <v-card outlined>
        <v-data-table
          item-key="name"
          :headers="headers"
          :items="getGroupsItems"
          :items-per-page="10000"
          hide-default-footer
          :expanded="expanded"
          @click:row="clickRow"
        >
          <template v-slot:item.action="{ item }">
            <v-btn depressed color="green" dark class="mr-3" @click="updateGroup(item)">
              수정
            </v-btn>
            <v-btn depressed color="red" dark @click="deleteGroup(item)">
              삭제
            </v-btn>
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td colspan="1" />
            <td :colspan="headers.length - 1">
              <v-row no-gutters class="mt-4 mb-2">
                <v-col cols="1" class="font-weight-bold">조건</v-col>
                <v-col>
                  <p v-for="(e, i) in afterReceivers(item)" :key="i" class="mb-0">
                    {{ `${i + 1}. ${e}` }}
                  </p>
                </v-col>
              </v-row>
              <v-row no-gutters class="mb-2">
                <v-col cols="1" class="font-weight-bold mb-2">유저</v-col>
                <v-col>
                  <p v-for="(e, i) in item.condition.userList" :key="i" class="mb-0">
                    {{ `${i + 1}. ${e}` }}
                  </p>
                </v-col>
              </v-row>
            </td>
          </template>
        </v-data-table>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { defineComponent, reactive, onBeforeMount, toRefs } from '@vue/composition-api'

export default defineComponent({
  setup(props, { root }) {
    const state = reactive({
      headers: [
        { text: '유저그룹 이름', value: 'name' },
        { text: '그룹 설명', value: 'detail' },
        { text: null, value: 'action' },
      ],
      getGroupsItems: [],
      expanded: [],
    })

    const afterReceivers = (item) => {
      const receivers = []
      if (item.condition.receivers.schoolList)
        item.condition.receivers.schoolList.forEach((e) => {
          let first = '학력 - '
          let second = ''
          let third = ''

          if (e.education === 'elementary') first += '초등학교'
          else if (e.education === 'middle') first += '중학교'
          else if (e.education === 'high') first += '고등학교'
          else if (e.education === 'nAgain' || e.education === 'halfAgain') first += 'N수'
          else if (e.education === 'parents') first += '학부모'
          else if (e.education === 'homeSchool') first += '검정고시'
          else if (e.education === 'mentor') first += '멘토'
          else if (e.education === 'other') first += '기타'

          if (e.kindList) {
            if (e.kindList[0] === 'general') second += ` - 일반`
            else if (e.kindList[0] === 'autonomous') second += ` - 자율`
            else if (e.kindList[0] === 'specialPurpose') second += ` - 특목`
            else if (e.kindList[0] === 'specialized') second += ` - 특성화`
            else if (e.kindList[0] === 'technical') second += ` - 전문`
            else if (e.kindList[0] === 'science') second += ` - 과학`
            else if (e.kindList[0] === 'international') second += ` - 국제`
            else if (e.kindList[0] === 'art') second += ` - 예술`
            else if (e.kindList[0] === 'foreign') second += ` - 외국어`
            else if (e.kindList[0] === 'physical') second += ` - 체육`
            else if (e.kindList[0] === 'alternative') second += ` - 대안`
            else if (e.kindList[0] === 'etc') second += ` - 기타`
            else if (e.kindList[0] === 'empty' || e.kindList[0] === 'nul') second += ` - NUL`
          } else if (e.education === 'middle' || e.education === 'high') third += ` - 전체`

          if (e.yearList && e.yearList[0]) third += ` - ${e.yearList[0]} 학년`
          else if (e.education === 'elementary' || e.education === 'middle' || e.education === 'high') third += ` - 전체`

          receivers.push(first + second + third)
        })
      if (item.condition.receivers.questionCountList)
        item.condition.receivers.questionCountList.forEach((e) => {
          if (e > 2) receivers.push(`질답횟수 - 3번 이상`)
          else receivers.push(`질답횟수 - ${e}번`)
        })
      if (item.condition.receivers.likeAvgList)
        item.condition.receivers.likeAvgList.forEach((e) => {
          receivers.push(`답변평균점수 - ${e}`)
        })
      if (item.condition.receivers.afterBeforeTimeList)
        item.condition.receivers.afterBeforeTimeList.forEach((e) => {
          let tmp
          if (e[1] === 24) tmp = '0~24시간'
          else if (e[1] === 48) tmp = '24~48시간'
          else if (e[1] === 72) tmp = '48~72시간'
          else if (e[1] === 0) tmp = '72시간 이후'
          receivers.push(`최종접속일 - ${tmp}`)
        })
      if (item.condition.receivers.sexList)
        item.condition.receivers.sexList.forEach((e) => {
          let tmp
          if (e === 'male') tmp = '남성'
          else if (e === 'female') tmp = '여성'
          else if (e === 'none') tmp = '기타'
          receivers.push(`성별 - ${tmp}`)
        })
      return receivers
    }

    const clickRow = (value) => {
      const index = state.expanded.indexOf(value)
      if (index === -1) {
        state.expanded.push(value)
      } else {
        state.expanded.splice(index, 1)
      }
    }

    const getGroups = async () => {
      try {
        const newGroups = await root.$store.dispatch('group/getListGroup', {})

        state.getGroupsItems = newGroups
      } catch (err) {
        console.error(err)
        alert(err)
      }
    }

    const updateGroup = (item) => {
      root.$router.push(root.$route.fullPath + `/${item.name}`)
      root.$store.state.group = item.name
    }

    const deleteGroup = async (item) => {
      if (!confirm('삭제하시겠습니까?')) return

      await root.$store
        .dispatch('group/deleteGroup', {
          name: item.name,
        })
        .then(() => {
          alert('삭제 완료!')
          getGroups()
        })
        .catch((err) => {
          console.error(err)
          alert(err)
        })
    }

    onBeforeMount(() => {
      getGroups()
    })

    return {
      ...toRefs(state),
      afterReceivers,
      clickRow,
      getGroups,
      updateGroup,
      deleteGroup,
    }
  },
})
</script>
